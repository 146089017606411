export const environment = {
  version: '1-0-0',
  buildDate: '202501150843',
  environment: 'production',
  release: 'b733edacf012d353c1f9924381ecb767bdf2c712',
  production: true,
  debug: false,
  baseUrl: 'https://leads.catch-talents.de',
  apiUrl: 'https://api.catch-talents.de',
  apiUrlFallback: 'https://api.catch-talents.de',
  companyBaseUrl: 'https://company.catch-talents.de',
  jobboardBaseUrl: 'https://jobboard.catch-talents.de',
};
